<template>
  <validation-observer tag="form" ref="observer" v-slot="{ valid }">
    <form method="post" action="" ref="form">
      <span v-html="tokenInput"></span>
      <div class="container-checkout-informations" :class="site">
        <input type="hidden" name="action" value="commerce/cart/update-cart" />
        <input type="hidden" name="shippingMethodHandle" value="1" />
        <!-- user is logged -->
        <div v-if="user">
          <input
            type="hidden"
            name="shippingAddressId"
            :value="selectedShippingAddress"
            v-if="addresses.length > 0"
          />
          <input
            type="hidden"
            name="billingAddressId"
            :value="
              billingSameAsShipping
                ? selectedShippingAddress
                : selectedBillingAddress
            "
            v-if="addresses.length > 0"
          />
          <checkout-card>
            <div>
              <customtitle :level="2" :style-of="5" class="mb-6">
                {{ $t('ttl-checkout-informations') }}
              </customtitle>
            </div>
            <div v-if="!cart.email">
              <custominput
                id="email"
                class="mb-8"
                type="email"
                name="Email"
                placeholder="email@site.com"
                vid="email"
              />
            </div>
            <div>
              <customer-address-card
                :address="address"
                v-for="(address, index) in addressesOrderedByDate"
                class="mb-6"
                :key="index"
                selectable
                :selected="selectedShippingAddress === address.id"
                @select="changeShippingAddress"
                :primary="
                  address.id ===
                    (customer ? customer.primaryShippingAddress.id : null)
                "
              ></customer-address-card>
              <customer-new-address-button
                @added="explicitUpdateShippingCartAddress"
              ></customer-new-address-button>
            </div>

            <checkbox
              class="mt-6"
              v-model="billingSameAsShipping"
              @input="doUpdateAddress"
            >
              {{ $t('p-checkout-billing-same-as-shipping') }}
            </checkbox>

            <input
              type="hidden"
              name="billingSameAsShipping"
              :value="billingSameAsShipping ? '1' : '0'"
            />

            <div v-if="!billingSameAsShipping" class="mt-6">
              <customtitle :level="3" :style-of="6" class="mb-6">
                {{ $t('ttl-checkout-billing-address') }}
              </customtitle>

              <customer-address-card
                :address="address"
                v-for="(address, index) in addressesOrderedByDate"
                :key="index"
                class="mb-6"
                :selected="selectedBillingAddress === address.id"
                @select="changeBillingAddress"
                selectable
                :primary="
                  address.id ===
                    (customer ? customer.primaryBillingAddress.id : null)
                "
              ></customer-address-card>
              <customer-new-address-button></customer-new-address-button>
            </div>
          </checkout-card>
        </div>
        <!-- user is not logged -->
        <div v-else>
          <!-- he could login -->
          <checkout-card class="mb-10">
            <div class="flex items-center">
              <customtitle :level="2" :style-of="5">
                {{ $t('p-checkout-already-client') }}
              </customtitle>
              <custombutton
                class="ml-6"
                variant="small"
                icon="user"
                @click="login"
                prevent
              >
                {{ $t('p-checkout-login') }}
              </custombutton>
            </div>
          </checkout-card>
          <!-- or continue without login -->
          <checkout-card>
            <customtitle :level="2" :style-of="5" class="mb-6">
              {{ $t('p-checkout-proceed-without-account') }}
            </customtitle>
            <div class="flex mb-6" v-if="!dontCreateAccount">
              <custominput
              :placeholder="$t('lbl-form-lastname')"
              class="w-1/2 mr-2 obligatory"
              id="lastName"
              rules="required"
              :value="cart.customer ? cart.customer.lastName : ''"
              :name="$t('lbl-form-lastname')"
              vid="lastName"
            />
            <custominput
              :placeholder="$t('lbl-form-firstname')"
              class="w-1/2 obligatory"
              id="firstName"
              rules="required"
              :value="cart.customer ? cart.customer.firstName : ''"
              :name="$t('lbl-form-firstname')"
              vid="firstName"
            />
            </div>
            <custominput
              id="email"
              class="mb-6 obligatory"
              type="email"
              :value="cart.email"
              name="Email"
              rules="required"
              placeholder="email@site.com"
              vid="email"
            />
            <div>
              <custominput
                :name="$t('lbl-form-password')"
                v-if="!dontCreateAccount"
                type="password"
                class="mt-6 obligatory"
                rules="required"
                :placeholder="$t('lbl-form-password')"
              />

              <checkbox class="mt-6" v-model="dontCreateAccount">
                {{ $t('p-checkout-no-account') }}
              </checkbox>

              <input
                type="hidden"
                name="registerUserOnOrderComplete"
                :value="dontCreateAccount ? '0' : '1'"
              />

              <checkbox
                  class="mt-3"
                  v-model="subscribeToNewsletter"
                  vid="subscribeToNewsletter"
                >
                  {{ $t('p-checkout-subscribe-to-newsletter') }}
                </checkbox>
                <input type="hidden" name="subscribeToNewsletterSubmitted" value="1" />
            </div>

            <div>
              <customtitle :level="3" :style-of="6" class="mt-6 mb-6">
                {{ $t('ttl-checkout-shipping-address') }}
              </customtitle>

              <customer-address-form
                model="shippingAddress"
                :address="cart.shippingAddress ? cart.shippingAddress : null"
                @change="doUpdateAddress"
                class="mt-3"
                shipping
              >
              </customer-address-form>
            </div>
            <checkbox
              class="mt-6"
              v-model="billingSameAsShipping"
              @input="doUpdateAddress"
            >
              {{ $t('p-checkout-billing-same-as-shipping') }}
            </checkbox>

            <input
              type="hidden"
              name="saveShippingAddressOnOrderComplete"
              value="1"
            />

            <input
              type="hidden"
              name="saveBillingAddressOnOrderComplete"
              value="1"
            />

            <input
              type="hidden"
              name="billingAddressSameAsShipping"
              :value="billingSameAsShipping ? '1' : '0'"
            />

            <div v-if="!billingSameAsShipping" class="mt-6">
              <customtitle :level="3" :style-of="6" class="mb-6">
                {{ $t('ttl-checkout-billing-address') }}
              </customtitle>

              <customer-address-form
                model="billingAddress"
                :address="cart.billingAddress ? cart.billingAddress : null"
                class="mt-3"
                billing
              ></customer-address-form>
            </div>
          </checkout-card>
        </div>
        <div>
          <checkout-card>
            <div v-if="cart.totalQty < 1">{{ $('p-empty-basket') }}</div>
            <div v-else>
              <cart
                @cart-products-ready="onCartProductsReady"
                :cartProducts="cartProducts"
                @initial-cart-data-ready="onInitialCartDataReady"
              >
                <template v-slot:header>
                  <customtitle :level="2" :style-of="5">
                    {{ $t('ttl-checkout-basket-resume') }}
                  </customtitle>
                </template>
                <template v-slot:footer>
                  <promocode class="mt-6 w-full"></promocode>
                  <add-voucher class="mt-6 w-full"></add-voucher>
                  <div class="mt-10">
                    <custombutton
                      @click="displayCustomNote"
                      icon="plus"
                      prevent
                      v-if="!customNoteDisplayed"
                      secondary
                    >
                      {{ $t('btn-checkout-custom-note') }}
                    </custombutton>
                    <customtextarea
                      :name="$t('ttl-checkout-custom-note')"
                      vid="fields[clientCustomNote]"
                      :value="
                        cart.clientCustomNote ? cart.clientCustomNote : ''
                      "
                      v-if="customNoteDisplayed"
                    ></customtextarea>
                  </div>
                  <div class="mt-10">
                    <checkbox
                      class="my-3"
                      v-model="acceptGeneralConditions"
                      :rules="{ required: { allowFalse: false } }"
                    >
                      {{ $t('p-checkout-accept-general-conditions') }}
                    </checkbox>
                    <custombutton
                      :loading="submitting"
                      :disabled="!canGoToNextStep && !valid"
                      @click="submit"
                      icon="arrow-right"
                      :selected="true"
                      stop
                      prevent
                    >
                      {{ $t('ttl-checkout-validate') }}
                    </custombutton>
                    <div>
                      <div
                        :class="
                          site === `ecomarket`
                            ? `text-red-base`
                            : `text-red-ecoshop-400`
                        "
                        class="py-4"
                        v-if="problematicSelectedCountry"
                      >
                        {{ $t('country-shipping-is-not-possibe') }}
                      </div>
                    </div>
                    <div class="text-red-base py-4" v-if="!addressIsSelected">
                      {{ $t('address-not-selected') }}
                    </div>
                  </div>
                </template>
              </cart>
            </div>
          </checkout-card>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { fetchCustomer, fetchAddresses, fetchCart } from '@/api'
import { ValidationObserver } from 'vee-validate'
import store from '@/store'
import Button from '@/components/Button'
import Title from '@/components/Title'
import Input from '@/components/Input'
import Customtextarea from '@/components/Textarea'
import CustomerAddressForm from '@/Customer/CustomerAddressForm'
import CustomerAddressCard from '@/Customer/CustomerAddressCard'
import CustomerNewAddressButton from '@/Customer/CustomerNewAddressButton'
import CheckoutCard from '@/components/CheckoutCard'
import Checkbox from '@/components/Checkbox'
import Cart from '@/components/Cart'
import Promocode from '@/components/Promocode'
import AddVoucher from '@/components/AddVoucher'
import { addToDataLayer, formatGAPrice } from '@/utils'

export default {
  components: {
    custombutton: Button,
    customtitle: Title,
    custominput: Input,
    Customtextarea,
    CustomerAddressForm,
    CustomerAddressCard,
    CheckoutCard,
    Checkbox,
    ValidationObserver,
    CustomerNewAddressButton,
    Cart,
    Promocode,
    AddVoucher
  },
  computed: {
    addressesOrderedByDate() {
      return [...this.addresses].sort(
        (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
      )
    },
    customNoteDisplayed() {
      if (this.cart.clientCustomNote) {
        return true
      } else {
        return this.customNoteButtonClicked
      }
    },
    someBelgiumOnlyItems() {
      return this.cart.lineItems.some(item => {
        // 3 is begliumOnly shipping category
        return item.snapshot.shippingCategoryId === 3
      })
    },
    currentAddress() {
      return this.cart.shippingAddressId
    },
    problematicSelectedCountry() {
      if (!this.cart?.shippingAddress?.countryCode) return false

      return (
        // only belgium case
        // BE is belgium
        this.cart.shippingAddress.countryCode !== 'BE' &&
        this.someBelgiumOnlyItems &&
        this.submitting === false
      )
    },
    addressIsSelected() {
      if (!this.user) {
        // address cannot be selected if there is no user, it's manual
        return true
      } else {
        return (
          this.cart.shippingAddressId &&
          this.addresses.some(a => a.id === this.cart.sourceShippingAddressId)
        )
      }
    },
    canGoToNextStep() {
      return (
        this.cart.totalQty > 0 &&
        this.addressIsSelected &&
        !this.problematicSelectedCountry &&
        !this.submitting
      )
    },
    ...mapState([
      'cart',
      'tokenInput',
      'user',
      'addresses',
      'customer',
      'url',
      'site'
    ])
  },
  watch: {
    cart: function(cart) {
      this.billingSameAsShipping = cart.billingSameAsShipping
      this.selectAddress()
    },
    user: async function() {
      await fetchAddresses()
      await fetchCart()
      await fetchCustomer()
    },
    addresses: function() {
      this.setTempCountryId()
    },
    tempCountryId: function() {
      this.updateAddress()
    }
  },
  data() {
    return {
      billingSameAsShipping: true,
      dontCreateAccount: false,
      submitting: false,
      selectedShippingAddress: null,
      selectedBillingAddress: null,
      customNoteButtonClicked: false,
      vouchers: [],
      acceptGeneralConditions: true,
      errorShipping: '',
      cartProducts: [],
      tempCountryId: null,
      subscribeToNewsletter: true,
    }
  },
  created() {
    this.selectAddress()
    this.setTempCountryId()
    if (this.cart) {
      this.billingSameAsShipping = this.cart.billingSameAsShipping
    }
  },
  methods: {
    setTempCountryId() {
      if (this.addresses.length > 0) {
        this.tempCountryId = parseInt(this.currentAddress.countryId)
      }
    },
    onCountryChange(countryId) {
      this.tempCountryId = parseInt(countryId)
    },
    onCartProductsReady(products) {
      this.cartProducts = products
    },
    scrollToTop() {
      if (this.cart.billingAddressId === null) {
        return ' '
      } else {
        window.scrollTo(0, 0)
      }
    },
    async submit() {
      const valid = await this.$refs.observer.validate()
      if (!this.canGoToNextStep) {
        return
      }
      if (valid) {
        this.submitting = true
        const data = new FormData(this.$refs.form)
        axios
          .post(`${this.url}/`, data, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
          })
          .then(() => {
            if(!this.user && !this.dontCreateAccount){
              addToDataLayer({
                event: 'sign_up_process',
                registration_success: true
              })
            }
            store.commit('setCheckoutStep', 2)
            this.scrollToTop()
          })
          .catch(() => {})
          .finally(() => {
            this.submitting = false
          })
      }
    },
    doUpdateAddress() {
      this.$nextTick(() => {
        this.updateAddress()
      })
    },
    async updateAddress() {
      this.submitting = true
      const data = new FormData(this.$refs.form)
      axios
        .post(`${this.url}/`, data, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        })
        .then(() => {
          fetchCart()
          fetchAddresses()
        })
        .catch(() => {})
        .finally(() => {
          this.submitting = false
        })
    },
    explicitUpdateShippingCartAddress(address) {
      this.submitting = true
      const data = new FormData()
      const formData = new FormData(this.$refs.form)

      data.append('action', 'commerce/cart/update-cart')
      data.append('CRAFT_CSRF_TOKEN', formData.get('CRAFT_CSRF_TOKEN'))
      data.append('shippingAddressId', address.id)

      axios
        .post(`${this.url}`, data, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        })
        .then(() => {
          fetchCart()
          fetchAddresses()
        })
        .catch(() => {})
        .finally(() => {
          this.submitting = false
        })
    },
    login() {
      store.commit('showLoginPopup')
    },
    changeShippingAddress(id) {
      this.selectedShippingAddress = id
      this.$nextTick(() => {
        this.updateAddress()
      })
    },
    changeBillingAddress(id) {
      this.selectedBillingAddress = id
      this.$nextTick(() => {
        this.updateAddress()
      })
    },
    selectAddress() {
      if (this.cart) {
        this.selectedBillingAddress = this.cart.sourceBillingAddressId
        this.selectedShippingAddress = this.cart.sourceShippingAddressId
      }
    },
    displayCustomNote() {
      this.customNoteButtonClicked = true
    },
    onInitialCartDataReady() {
      if(this.cart) {
        addToDataLayer({
          event: 'checkout_steps',
          price: formatGAPrice(this.cart.totalPriceAsCurrency),
          step_name: 'checkout'
        })
      }
      
    }
  }
}
</script>

<style lang="postcss">
.container-checkout-informations {
  @apply grid grid-cols-1 gap-10;
  @screen lg {
    @apply grid-cols-2;
  }
}
.obligatory .mr-3:after {
  @apply text-grey-300;
  content: '*';
}

.ecomarket .container-checkout-informations {
  @screen lg {
    @apply gap-10;
  }
}

.ecoshop .container-checkout-informations {
  @screen lg {
    @apply gap-12;
  }
}
</style>
