<template>
  <validation-observer tag="div" ref="observer">
    <form method="post" action="" ref="form">
      <span v-html="tokenInput"></span>
      <input type="hidden" name="action" value="commerce/payments/pay" />
      <input type="hidden" name="orderEmail" :value="cart.email" />
      <input type="hidden" name="orderNumber" :value="cart.number" />
      <input type="hidden" name="gatewayId" :value="gatewayId" />
      <input
        type="hidden"
        :name="`paymentForm[${gatewayName}][paymentMethod]`"
        :value="paymentMethod"
      />
      <input
        type="hidden"
        :name="`paymentForm[${gatewayName}][category]`"
        :value="category"
      />
      <input
        type="hidden"
        :value="selectedIssuer"
        :name="`paymentForm[${gatewayName}][issuer]`"
        v-if="selectedIssuer"
      />
      <checkout-card :class="site">
        <slot></slot>
        <customtitle :level="2" :style-of="5" class="mb-6">
          {{ $t('ttl-checkout-select-payment-method') }}
        </customtitle>
        <div class="lg:flex lg:flex-wrap">
          <payment-card
            v-if="site === 'ecomarket'"
            class="cursor-pointer"
            id="sodexo"
            @click="
              () => {
                selectPaymentInfos('sodexo', 'sodexo-ecopass', 'eco')
              }
            "
            :selected="
              selectedMethod === 'sodexo' && selectedIssuer === 'sodexo-ecopass'
            "
          >
            <div class="flex flex-col">
              <span class="text-sm mb-2">
                {{ $t('sodexo-clarity') }}
                <span class="font-black">Sodexo</span>!
              </span>
              <img src="../assets/images/sodexo.png" alt="" />
              <p class="title-payment-eco-card">
                {{ $t('ttl-ecocard') }}
              </p>
            </div>
          </payment-card>
          <!--
          <payment-card
            class="cursor-pointer"
            id="sodexo"
            @click="
              () => {
                selectPaymentInfos('sodexo', 'sodexo-cadeaupass', 'gift')
              }
            "
            :selected="
              selectedMethod === 'sodexo' &&
                selectedIssuer === 'sodexo-cadeaupass'
            "
          >
            <div class="flex flex-col">
              <img src="../assets/images/sodexo.png" alt="" />
              <p class="mt-3 text-lg text-green-dark">
                {{ $t('ttl-giftcard') }}
              </p>
            </div>
          </payment-card>
          -->
          <payment-card
            v-if="site === 'ecomarket'"
            class="cursor-pointer"
            id="monizze"
            @click="
              () => {
                selectPaymentInfos('monizze', 'monizze-eco', 'eco')
              }
            "
            :selected="
              selectedMethod === 'monizze' && selectedIssuer === 'monizze-eco'
            "
          >
            <div class="flex flex-col">
              <img src="../assets/images/monizze.png" alt="" />
              <p class="title-payment-eco-card">
                {{ $t('ttl-ecocard') }}
              </p>
            </div>
          </payment-card>
          
          <payment-card
            v-if="site === 'ecomarket'"
            class="cursor-pointer"
            id="monizze"
            @click="
              () => {
                selectPaymentInfos('monizze', 'monizze-cadeau', 'gift')
              }
            "
            :selected="
              selectedMethod === 'monizze' &&
                selectedIssuer === 'monizze-cadeau'
            "
          >
            <div class="flex flex-col">
              <img src="../assets/images/monizze.png" alt="" />
              <p class="mt-3 text-lg text-green-dark whitespace-nowrap">
                {{ $t('ttl-payment-giftcard') }}
              </p>
            </div>
          </payment-card>
         
          <payment-card
            class="cursor-pointer"
            id="edenred"
            @click="
              () => {
                selectPaymentInfos('edenred')
              }
            "
            :selected="selectedMethod === 'edenred' && category === 'eco'"
          >
            <div class="flex flex-col">
              <img src="../assets/images/edenred-ecoCheque.png" alt="" />
            </div>
          </payment-card>
          <payment-card
            class="cursor-pointer"
            id="edenred"
            @click="
              () => {
                selectPaymentInfos('edenred', null, 'conso')
              }
            "
            :selected="selectedMethod === 'edenred' && category === 'conso'"
          >
            <div class="flex flex-col">
              <img src="../assets/images/edenred-conso.png" alt="" />
            </div>
          </payment-card>
          <payment-card
            class="cursor-pointer"
            id="edenred"
            @click="
              () => {
                selectPaymentInfos('edenred', null, 'gift')
              }
            "
            :selected="selectedMethod === 'edenred' && category === 'gift'"
          >
            <div class="flex flex-col">
              <img src="../assets/images/edenred-comliments.png" alt="" />
            </div>
          </payment-card>
          <payment-card
            class="cursor-pointer"
            id="bancontact"
            @click="
              () => {
                selectPaymentInfos('bancontact')
              }
            "
            :selected="selectedMethod === 'bancontact'"
          >
            <img src="../assets/images/bancontact.png" alt="" />
          </payment-card>
          <payment-card
            class="cursor-pointer"
            id="visa"
            @click="
              () => {
                selectPaymentInfos('visa')
              }
            "
            :selected="selectedMethod === 'visa'"
          >
            <img src="../assets/images/visa.png" alt="" />
          </payment-card>
          <payment-card
            class="cursor-pointer"
            id="mastercard"
            @click="
              () => {
                selectPaymentInfos('mastercard')
              }
            "
            :selected="selectedMethod === 'mastercard'"
          >
            <img src="../assets/images/mastercard.svg" alt="" />
          </payment-card>
          <payment-card
            v-if="site === 'ecomarket'"
            class="cursor-pointer"
            id="american_express"
            @click="
              () => {
                selectPaymentInfos('american_express')
              }
            "
            :selected="selectedMethod === 'american_express'"
          >
            <img src="../assets/images/american_express.svg" alt="" />
          </payment-card>
          <payment-card
            class="cursor-pointer"
            id="maestro"
            @click="
              () => {
                selectPaymentInfos('maestro')
              }
            "
            :selected="selectedMethod === 'maestro'"
          >
            <img src="../assets/images/maestro.png" alt="" />
          </payment-card>
          <payment-card
            class="cursor-pointer"
            id="paypal"
            @click="
              () => {
                selectPaymentInfos('paypal')
              }
            "
            :selected="selectedMethod === 'paypal'"
          >
            <img src="../assets/images/paypal.png" alt="" />
          </payment-card>
        </div>
        <div class="mt-10 flex justify-end items-center">
          <custombutton :loading="submitting" :disabled="!selectedMethod">
            {{ $t('btn-checkout-pay') }}
          </custombutton>
        </div>
      </checkout-card>
    </form>
  </validation-observer>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Button from '@/components/Button'
import CheckoutCard from '@/components/CheckoutCard'
import PaymentCard from '@/components/PaymentCard'
import Title from '@/components/Title'
import { ValidationObserver } from 'vee-validate'
import { addToDataLayer, formatGAPrice } from '@/utils'

export default {
  components: {
    custombutton: Button,
    CheckoutCard,
    ValidationObserver,
    PaymentCard,
    customtitle: Title
  },
  props: {
    user: Object,
    redirect: String
  },
  computed: {
    isMollie() {
      return [
        'sodexo',
        'monizze',
        'bancontact',
        'visa',
        'mastercard',
        'american_express',
        'maestro',
        'paypal'
      ].includes(this.selectedMethod)
    },
    isMultisafe() {
      return this.selectedMethod === 'edenred'
    },
    gatewayName() {
      if (this.isMollie) {
        return 'mollie'
      } else if (this.isMultisafe) {
        return 'multisafepay'
      } else {
        return ''
      }
    },
    gatewayId() {
      if (this.isMollie) {
        return 5
      } else if (this.isMultisafe) {
        return 7
      } else {
        return ''
      }
    },
    paymentMethod() {
      if (['sodexo', 'monizze'].includes(this.selectedMethod)) {
        return 'voucher'
      } else if (this.selectedMethod === 'bancontact') {
        return this.selectedMethod
      } else if (
        ['visa', 'mastercard', 'american_express', 'maestro'].includes(
          this.selectedMethod
        )
      ) {
        return 'creditcard'
      } else if (this.selectedMethod === 'paypal') {
        return 'paypal'
      } else if (
        this.selectedMethod === 'edenred' && 
        this.category === 'eco'
      ) {
        return 'EDENECO'
      } else if (
        this.selectedMethod === 'edenred' &&
        this.category === 'gift'
      ) {
        return 'EDENCOM'
      } else if (
        this.selectedMethod === 'edenred' &&
        this.category === 'conso'
      ) {
        return 'EDENCONSUM'
      } else {
        return ''
      }
    },
    ...mapState(['cart', 'tokenInput', 'token', 'url', 'site'])
  },
  data() {
    return {
      submitting: false,
      selectedMethod: null,
      category: 'eco',
      selectedIssuer: null,
    }
  },
  created() {
    if(this.cart){
      addToDataLayer({
        event: 'checkout_steps',
        price: formatGAPrice(this.cart.totalPriceAsCurrency),
        step_name: 'payment'
      }) 
    }
  },
  methods: {
    selectPaymentInfos(method, issuer = null, category = 'eco') {
      if (
        this.selectedMethod !== method ||
        this.selectedIssuer !== issuer ||
        this.category !== category
      ) {
        this.selectedMethod = method
        this.selectedIssuer = issuer
        this.category = category
      } else {
        this.selectedMethod = null
        this.selectedIssuer = null
        this.category = 'eco'
      }
    },
    async submit() {
      const valid = await this.$refs.observer.validate()

      if (valid) {
        addToDataLayer({
          event: 'add_payment_info',
          price: formatGAPrice(this.cart.totalPriceAsCurrency),
          step_name: 'payment',
          payment_type: this.paymentMethod
        }) 
        this.submitting = true
        const data = new FormData(this.$refs.form)
        axios
          .post(`${this.url}/`, data, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
          })
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            this.submitting = false
          })
      }
    },
  }
}
</script>
<style lang="postcss" scoped>
.title-payment-eco-card {
  @apply mt-3 text-lg;
}

.ecomarket .title-payment-eco-card {
  @apply text-ecomarket-blue-300;
}

.ecoshop .title-payment-eco-card {
  @apply text-grey-300;
}
</style>
