<template>
  <component
    :is="isMounted ? 'form' : 'div'"
    method="post"
    @submit.prevent
    ref="form"
  >
    <popup ref="popup">
      <div class="block mb-6 text-xl">
        {{ $t('p-age-notice') }}
      </div>
      <custombutton
        :loading="submitting"
        prevent
        @click="addToCart"
        class="mr-3"
      >
        {{ $t('btn-yes') }}
      </custombutton>
      <custombutton prevent @click="hideAgeNoticePopup">
        {{ $t('btn-no') }}
      </custombutton>
    </popup>
    <input type="hidden" name="action" value="commerce/cart/update-cart" />
    <span v-html="tokenInput"></span>
    <input type="hidden" name="qty" value="1" />
    <input type="hidden" name="shippingMethodHandle" value="1" />
    <input type="hidden" name="purchasableId" :value="id" />
    <tooltip top-left :disabled="hasStock">
      <template #trigger>
        <custombutton
          :icon="`${hideIcon ? '' : 'shopping-bag'}`"
          type="submit"
          invertIcon
          :loading="submitting"
          prevent
          :hideText="compact && !displayPrice"
          :reverseIcon="displayPrice"
          @click="handleClick"
          class="add-to-cart-button"
          :disabled="!hasStock"
          :color="color"
          :outline="outline"
        >
          <template #text>
            <price
              v-if="displayPrice && price"
              :amount="price"
              class="product-card__price"
            ></price>
            <span v-if="!compact">{{ $t('btn-add-to-cart') }}</span>
          </template>
          <div class="add-to-cart-overlay" v-if="compact" :class="{ compact }">
            +
          </div>
        </custombutton>
      </template>
      <template #content>
        {{ $t('p-no-stock') }}
      </template>
    </tooltip>
  </component>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import store from '@/store'
import Button from '@/components/Button.vue'
import Price from '@/components/Price'
import Tooltip from '@/components/Tooltip'
import Popup from '@/components/Popup'
import { addToDataLayer, formatGAPrice } from '@/utils'

export default {
  components: {
    custombutton: Button,
    Price,
    Tooltip,
    Popup
  },
  props: {
    id: Number,
    compact: Boolean,
    displayPrice: {
      type: Boolean,
      default: false
    },
    price: String,
    hideIcon: Boolean,
    disabled: Boolean,
    color: String,
    legend: String,
    legendPosition: String,
    hasStock: {
      type: Boolean,
      default: true
    },
    outline: Boolean,
    ageNotice: {
      type: Boolean,
      required: true,
      default: false
    },
    title: String,
    supplierTitle: String,
    categoryTitle: String
  },
  mounted() {
    this.isMounted = true
  },
  data() {
    return {
      submitting: false,
      isMounted: false
    }
  },
  computed: mapState(['tokenInput', 'url', 'site']),
  methods: {
    handleClick() {
      if (this.ageNotice) {
        this.displayAgeNoticePopup()
      } else {
        this.addToCart()
      }
    },
    addToCart() {
      this.submitting = true
      var data = new FormData(this.$refs.form)
      axios
        .post(`${this.url}/`, data, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        })
        .then(res => {
          addToDataLayer({
            event: 'add_to_cart',
            item_id: this.id,
            item_name: this.title,
            item_brand: this.supplierTitle,
            item_category: this.categoryTitle,
            quantity: 1,
            price: formatGAPrice(this.price)
          })
          store.commit('updateCart', res.data.cart)
          store.commit('openSideBasket')

          if (!res.success) {
            res.errors.forEach(error => {
              this.$toast.error(error[0])
            })
          }
        })
        .catch(() => {})
        .finally(() => {
          this.submitting = false
          this.hideAgeNoticePopup()
        })
    },
    displayAgeNoticePopup() {
      this.$refs.popup.open()
    },
    hideAgeNoticePopup() {
      this.$refs.popup.close()
    }
  }
}
</script>

<style lang="postcss" scoped>
.add-to-cart-button {
  @apply w-full overflow-hidden relative;

  &:hover {
    .add-to-cart-overlay {
      @apply opacity-100;
    }
  }
}

.add-to-cart-overlay {
  @apply absolute inset-0 flex items-center justify-center text-xl opacity-0 pointer-events-none transition duration-200;
}

.ecomarket {
  .add-to-cart-overlay {
    @apply bg-ecomarket-blue-400 text-white;
  }
}

.ecoshop {
  .add-to-cart-overlay {
    @apply bg-white text-red-ecoshop-400;
  }

  .add-to-cart-overlay.compact {
    @apply bg-red-ecoshop-400 text-white;
  }
}
</style>
